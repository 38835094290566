import * as React from "react"
import { Layout } from "../components/layout"
import { css } from "@emotion/react"
import ShopSection from "../components/shopSection"
import PhotoGallerySection from "../components/photoGallerySection"
import ContactUsSection from "../components/contactUsSection"

export default function NotFoundPage() {
    return (
        <Layout>
            <div css={wrapper}>
                <h1>Page Not Found</h1>
            </div>
            <ShopSection />
            <PhotoGallerySection />
            <ContactUsSection />
        </Layout>
    )
}

const wrapper = css`
    width: 80%;
    display: block;
    margin: 10vh auto 20vh;
    text-align: center;
`
